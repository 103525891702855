import React from 'react'
import {
  VStack,
  Text,
  Input,
  Button,
  Center,
  FormControl,
  FormErrorMessage,
  FormLabel,
  useToast,
} from 'ui-components'
import PageContainer from '@/components/PageContainer'
import LifetimeQuotesIcon from '@/components/icons/LifetimeQuotesIcon'
import { Formik, FormikProps, ErrorMessage, Field, FieldInputProps } from 'formik'
import * as Yup from 'yup'
import { graphql } from 'react-relay'
import { useMutation } from 'relay-hooks'
import { loginMutation } from '@/__generated__/loginMutation.graphql'
import { useRouter } from 'next/router'
import { NextPageContext } from 'next'
import { StyleForm } from '@/components/Forms/StyleForm'

const LoginSchema = Yup.object()
  .shape({
    email: Yup.string().email('Invalid email').required('Required'),
  })
  .defined()
interface FormValues {
  email: string
}
interface LoginFormProps {
  onSubmit: (values: FormValues) => void
  submitting: boolean
  initialValues: FormValues
}

const LoginForm = ({ onSubmit, submitting, initialValues }: LoginFormProps) => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={LoginSchema}
      onSubmit={async (values, actions) => {
        await onSubmit(values)
        actions.setSubmitting(false)
      }}
    >
      {(props: FormikProps<FormValues>) => (
        <StyleForm>
          <Field name="email">
            {({
              field,
              form,
            }: {
              field: FieldInputProps<string>
              form: FormikProps<FormValues>
            }) => (
              <FormControl
                id="email"
                isRequired
                isInvalid={form.errors?.email && form.touched?.email ? true : false}
              >
                <FormLabel htmlFor="email">Email</FormLabel>
                <Input {...field} placeholder="joe@example.com" id="email" />
                <ErrorMessage component={FormErrorMessage} name="email" />
              </FormControl>
            )}
          </Field>

          <Button isFullWidth type="submit" isLoading={props.isSubmitting || submitting}>
            Login
          </Button>
        </StyleForm>
      )}
    </Formik>
  )
}

interface LoginProps {
  email: string
}
const Login = ({ email }: LoginProps) => {
  const router = useRouter()
  const toast = useToast()
  const [mutate, { loading }] = useMutation<loginMutation>(
    graphql`
      mutation loginMutation($input: SendSignInEmailInput!) {
        sendSignInEmail(input: $input) {
          __typename
          ... on SendSignInEmailSuccessPayload {
            __typename
            success
          }
          ... on SendSignInEmailErrorPayload {
            __typename
            error {
              message
            }
          }
        }
      }
    `,
    {
      onCompleted: () => {
        router.push('/login/pending')
      },
    }
  )
  const onSubmit = async ({ email }: FormValues) => {
    try {
      await mutate({
        variables: {
          input: { email },
        },
      })
    } catch (e) {
      toast({
        title: 'Could Not Login. Please Try Again',
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
  }

  return (
    <PageContainer title="Login to MyLifetime" showNav={false}>
      <VStack spacing={4} align="stretch" width="100%">
        <Center py={8}>
          <LifetimeQuotesIcon w={104} h={104} />
        </Center>
        <Text fontSize="md" color="grey.900" mb="4">
          Enter your email below to log in to MyLifetime. Test
        </Text>
        <LoginForm onSubmit={onSubmit} submitting={loading} initialValues={{ email }} />
      </VStack>
    </PageContainer>
  )
}
Login.getInitialProps = ({ query }: NextPageContext) => {
  const email = query.email ? query.email : ''
  return { email }
}
export default Login
