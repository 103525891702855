/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type SendSignInEmailInput = {
    email: string;
};
export type loginMutationVariables = {
    input: SendSignInEmailInput;
};
export type loginMutationResponse = {
    readonly sendSignInEmail: {
        readonly __typename: "SendSignInEmailSuccessPayload";
        readonly __typename: "SendSignInEmailSuccessPayload";
        readonly success: boolean;
    } | {
        readonly __typename: "SendSignInEmailErrorPayload";
        readonly __typename: "SendSignInEmailErrorPayload";
        readonly error: {
            readonly message: string;
        };
    } | {
        /*This will never be '%other', but we need some
        value in case none of the concrete values match.*/
        readonly __typename: "%other";
    };
};
export type loginMutation = {
    readonly response: loginMutationResponse;
    readonly variables: loginMutationVariables;
};



/*
mutation loginMutation(
  $input: SendSignInEmailInput!
) {
  sendSignInEmail(input: $input) {
    __typename
    ... on SendSignInEmailSuccessPayload {
      __typename
      success
    }
    ... on SendSignInEmailErrorPayload {
      __typename
      error {
        message
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": null,
    "kind": "LinkedField",
    "name": "sendSignInEmail",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "success",
            "storageKey": null
          }
        ],
        "type": "SendSignInEmailSuccessPayload",
        "abstractKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "message",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "type": "SendSignInEmailErrorPayload",
        "abstractKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "loginMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "loginMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "d4e320b0a95b1581297b488f045bf96b",
    "id": null,
    "metadata": {},
    "name": "loginMutation",
    "operationKind": "mutation",
    "text": "mutation loginMutation(\n  $input: SendSignInEmailInput!\n) {\n  sendSignInEmail(input: $input) {\n    __typename\n    ... on SendSignInEmailSuccessPayload {\n      __typename\n      success\n    }\n    ... on SendSignInEmailErrorPayload {\n      __typename\n      error {\n        message\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'be0d10836ca7cb8815191706c4c2ac04';
export default node;
