import React from 'react'
import { Form } from 'formik'
import { VStack } from 'ui-components'

export const StyleForm: React.FC = (props) => {
  return (
    <Form>
      <VStack spacing={4} align="stretch">
        {props.children}
      </VStack>
    </Form>
  )
}
